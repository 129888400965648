import { h } from 'preact'
import { Link } from 'preact-router/match'

const Header = ({ user, logout }) => (
  <header class="navbar hide-sm">
    {user?.name ?
      <>
        <h1><img id='logo' src='/assets/icons/android-chrome-512x512.png' alt='D' />ocuStamp</h1>
        <section class="navbar-section">
          <Link activeClassName="active" href="/">Scan</Link>
          <Link activeClassName="active" href="/docs">Docs</Link>
          <Link activeClassName="active" href="/marks">Marks</Link>
          <Link activeClassName="active" href="/users">Users</Link>
        </section>
        <section class="navbar-section">
          <Link activeClassName="active" href='/settings'>Settings</Link>
          <Link onClick={logout} style={{ display: 'flex', 'align-items': 'center' }}>
            {user?.name} &nbsp;
            <i class="icon icon-shutdown" />
          </Link>
        </section>
      </>
      :
      <>
        <h1>DOCUMARK</h1>
        <section class="navbar-section">
          <Link activeClassName="active" href="/">Login</Link>
        </section>
      </>
    }
  </header>
)

export default Header
