import {h} from 'preact'
import {Router, route} from 'preact-router'
import {useState, useEffect} from 'preact/hooks'
import {useQuery} from '@apollo/client'

import Q from './q'

import Header from './components/header'
import Home from './routes/home'
import Docs from './routes/docs'
import Login from './routes/login'
import Admin from './routes/admin'
import Marks from './routes/marks'
import Users from './routes/users'
import Settings from './routes/settings'

const App = ({link}) => {

  const {data: {userMy = {}} = {}, loading} = useQuery(Q.Q_USER_MY, {fetchPolicy: 'cache-first'})
  const [user, setUser] = useState(userMy)
  console.log('- userMy ->', userMy, loading)

  useEffect(() => {
    if (userMy?.name) setUser(userMy)
    return () => {}
  }, [userMy])

  const updateUser = (data) => {
    if (!data || !data.token || !data.user) return
    localStorage.setItem('token', data.token)
    document.cookie = `token=${data.token}`
    link.subscriptionClient.close(false, false)
    setUser(data.user)
    setTimeout(() => route('/', true), 500)
  }

  const logout = (e) => {
    e.preventDefault()
    localStorage.clear()
    link.subscriptionClient.close(false, false)
    setUser({})
    setTimeout(() => route('/', true), 500)
  }

  console.log('- user ->', user, 'loading->', loading)
  // if (loading) return <p>Loading..</p>

  return (
    <div id="app">
      <Header user={user} logout={logout} />
      <div id="content">
        {user?.name ?
          <Router>
            <Home path="/" user={user} />
            <Docs path="/docs" user={user} />
            <Marks path="/marks" user={user} />
            <Users path="/users" user={user} />

            <Settings path="/settings/" user={user} />
            <Admin path="/admin" user={user} />

          </Router>
          :
          <Login updateUser={updateUser} user={user} />
        }

        {user?.name && loading &&
          <div class="p-centered">
            <div class="loading loading-lg" />
          </div>
        }
      </div>
    </div>
  )
}

export default App
