import 'spectre.css/dist/spectre.min.css'
import 'spectre.css/dist/spectre-icons.min.css'
import './style'

import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import App from './app'

let uri = 'wss://docustamp.art/graphql'

try {
  if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
    uri = 'ws://localhost:4031/graphql'
  }
} catch (e) { }

const link = new WebSocketLink({
  uri,
  options: {
	reconnect: true,
	timeout: 5000,
	reconnectionAttempts: Number.MAX_VALUE,
	connectionParams: () => ({
	  token: localStorage.getItem('token')
	})
  },
})

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})

const Root = () => (
  <ApolloProvider client={client}>
    <App client={client} link={link} />
  </ApolloProvider>
)

export default Root
