import { gql } from '@apollo/client'

export default {
  M_LOGIN: gql`mutation($inp: inUserLogin!) {
    userLogin(inp: $inp) {
      status
      token
      user {
        id
        name
        admin
      }
    }
  }`,

  Q_USER_MY: gql`query {
    userMy {
      id
      name
      email
      email_send
      tg
      tg_send
      admin
    }
  }`,

  Q_USER_EVERY: gql`query {
    userEvery {
      total
      items {
        id
        name
        email
        marks {
          items {
            id
            name
          }
        }
      }
    }
  }`,

  M_USER_MOD: gql`mutation($inp: inUser!) {
    userAddMod(inp: $inp) {
      id
      name
      email
      marks {
        total
        items {
          id
          name
        }
      }
    }
  }`,

  M_USER_MY_MOD: gql`mutation($inp: inUserMy!) {
    userMyMod(inp: $inp) {
      id
      name
      email
      email_send
      tg
      tg_send
      admin
    }
  }`,

  M_USER_REM: gql`mutation($id: ID!) {
    userRem(id: $id)
  }`,

  M_USER_TG: gql`mutation($pin: Int!) {
    userTgLink(pin: $pin) {
      id
      tg
    }
  }`,

  M_DOC_ADD: gql`mutation($inp: inDocAdd!) {
    docAdd(inp: $inp) {
      id
      dt
      img
      pages
      status
      progress
      rendered
    }
  }`,

  Q_DOC_EVERY: gql`query{
    docEvery {
      total
      items {
        id
        dt
        img
        pages
        status
        progress
        rendered
      }
    }
  }`,

  Q_DOC_ID: gql`query($id: ID!) {
    docId(id: $id) {
      id
      dt
      img
      pages
      status
      progress
      rendered
    }
  }`,

  Q_DOC_SUB: gql`subscription {
    doc {
      id
      dt
      img
      pages
      status
      progress
      rendered      
    }
  }`,

  Q_MARK_EVERY: gql`query {
    markEvery {
      total
      items {
        id
        dt
        name
        imgs
        mm
        my
        type
      }
    }
  }`,

  M_DOC_RERENDER: gql`mutation($id: ID!) {
    docRerender(id: $id) {
      id
      dt
      img
      pages
      status
      progress
    }
  }`,

  M_MARK_ADD: gql`mutation($id: ID, $inp: inMark!) {
    markAddMod(id: $id, inp: $inp) {
      id
      dt
      name
      imgs
      mm
    }
  }`,

  M_MARK_REM: gql`mutation($id: ID!) {
    markRem(id: $id)
  }`,
}